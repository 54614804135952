<template>
  <div>
    <FilterReset @click="resetFilter"></FilterReset>
    <v-row cols="12">
      <!-- Date filters - Start -->
      <v-col cols="12" sm="6" md="5">
        <FilterRange :title="$t('general.reports.date') + '：'" :title_width="2">
          <template v-slot:min>
            <GDatePicker :value="filters.startDate" type="date" @change="filterStartDate" />
          </template>
          <template v-slot:max>
            <GDatePicker :value="filters.endDate" type="date" @change="filterEndDate" />
          </template>
        </FilterRange>
      </v-col>
      <!-- Date filters - End -->

      <!-- Project filter - Start -->
      <v-col cols="12" sm="6" md="2">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div multiple v-on="on">
                  <v-combobox
                    ref="filterProject"
                    :items="projects"
                    :item-value="'id'"
                    :item-text="'name'"
                    :label="$t('general.assignmentSheet.project')"
                    @change="filterByProject"
                    multiple
                    chips
                    deletable-chips
                    v-model="selectProjects"
                  />
                </div>
            </template>
            <span>{{ selectProjects ? tooltipItem(selectProjects) : $t('general.tooltip.noSelect') }}</span>
        </v-tooltip>
      </v-col>
      <!-- Project filter - End -->

      <!-- Member filter - Start -->
      <v-col cols="12" sm="6" md="2">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div multiple v-on="on">
                  <v-combobox
                    ref="filterMember"
                    :items="members"
                    item-value="id"
                    item-text="name"
                    :label="$t('general.assignmentSheet.member')"
                    @change="filterByMember"
                    multiple
                    chips
                    deletable-chips
                    v-model="selectMembers"
                  />
                </div>
            </template>
            <span>{{ selectMembers ? tooltipItem(selectMembers) : $t('general.tooltip.noSelect') }}</span>
        </v-tooltip>
      </v-col>
      <!-- Member filter - End -->

      <!-- Team filter - Start -->
      <v-col cols="12" sm="6" md="2">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div multiple v-on="on">
                  <v-combobox
                    ref="filterTeam"
                    :items="teams"
                    :item-value="'id'"
                    :item-text="'name'"
                    :label="$t('general.assignmentSheet.teams')"
                    @change="filterByTeams"
                    multiple
                    chips
                    deletable-chips
                    v-model="selectTeams"
                  />
                </div>
            </template>
            <span>{{ selectTeams ? tooltipItem(selectTeams) : $t('general.tooltip.noSelect') }}</span>
        </v-tooltip>
      </v-col>
      <!-- Team filter - End -->
    </v-row>
  </div>
</template>

<script>
import { getAll } from '@services/crud';
import GDatePicker from '@views/_components/form_input/GDatePicker.vue';
import FilterRange from '@views/_components/datatable_filter/TableFilterSlotRange';
import FilterReset from '@views/_components/datatable_filter/TableFilterReset';

export default {
  components: {
    GDatePicker,
    FilterRange,
    FilterReset,
  },

  props: {
    filters: { type: Object, default: () => {} },
  },

  data: () => ({
    projects: [],
    members: [],
    teams: [],
    selectProjects: [],
    selectMembers: [],
    selectTeams: [],
  }),

  async created() {
    await this.getSelectBoxData();
    const currentQueryMember = this.$route.query.member;
    const currentQueryProject = this.$route.query.project;
    const currentQueryTeam = this.$route.query.team;
    let currentFilterMember = [];
    let currentFilterProject = [];
    let currentFilterTeam = [];

    // sync query string member to filter
    if (currentQueryMember) {
      if (typeof currentQueryMember === 'string') {
        let selectedMember = currentQueryMember.split(',').map(Number);
        currentFilterMember = this.members.filter(member => selectedMember.includes(member.id));
      } else {
        let selectedMember = currentQueryMember.map(Number);
        currentFilterMember = this.members.filter(member => selectedMember.includes(member.id));
      }
    }
    this.selectMembers = currentFilterMember;

    // sync query string project to filter
    if(currentQueryProject){
      if (typeof currentQueryProject === 'string') {
        let selectedProject = currentQueryProject.split(',').map(Number);
        currentFilterProject = this.projects.filter(project => selectedProject.includes(project.id));
      } else {
        let selectedProject = currentQueryProject.map(Number);
        currentFilterProject = this.projects.filter(project => selectedProject.includes(project.id));
      }
    }
    this.selectProjects = currentFilterProject;

    // sync query string team to filter
    if(currentQueryTeam){
      if (typeof currentQueryTeam === 'string') {
        let selectedTeam = currentQueryTeam.split(',').map(Number);
        currentFilterTeam = this.teams.filter(team => selectedTeam.includes(team.id));
      } else {
        let selectedTeam = currentQueryTeam.map(Number);
        currentFilterTeam = this.teams.filter(team => selectedTeam.includes(team.id));
      }
    }
    this.selectTeams = currentFilterTeam;

  },

  computed: {
    // Selected project if using v-select
    // selectedProject() {
    //   // return parseInt(this.filters.project ?? 0);
    // },
    // Selected project if using v-combobox without multiple
    // selectedProject: {
    //   // the value to output
    //   get() {
    //     // if a value has not been set
    //     if (!this.setValue) {
    //       // return the default
    //       return { id: 0, name: '(All Project)' };
    //     } else {
    //       // else return whatever you would like
    //       return this.setValue;
    //     }
    //   },
    //   // the function that happens when the combo box is set
    //   set(val) {
    //     // update the set value, combo box no longer show the default value
    //     this.setValue = val;
    //   },
    // },

    styles() {
      return {
        left: this.drawerOpen == true ? '286px' : '0',
        width: this.drawerOpen == true ? 'calc(100% - 286px)' : '100%',
      };
    },
  },

  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    getSelectBoxData: async function() {
      let url = '/assignment-sheet/select-box';
      try {
        const res = await getAll(url);
        // const allProject = { id: 0, name: '(All Project)' };
        this.projects = [...res.project];
        this.members = [...res.member];
        this.teams = [...res.teams]
      } catch (error) {
        console.log(error);
      }
    },

    // Filter startDate
    filterStartDate(startDate) {
      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ startDate } },
      });
    },

    // Filter endDate
    filterEndDate(endDate) {
      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ endDate } },
      });
    },

    // Filter by project
    filterByProject(projects) {
      // Define the project as container
      const project = [] || undefined;

      // Loop the project for type array
      for (let i = 0; i < projects.length; i++) {
        // Get the project id and push to container
        project.push(projects[i].id);
      }

      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ project } },
      });
    },

    // Filter by member
    filterByMember(members) {
      // Define the project as container
      const member = [] || undefined;

      // Loop the project for type array
      for (let i = 0; i < members.length; i++) {
        // Get the project id and push to container
        member.push(members[i].id);
      }

      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ member } },
      });
    },

    filterByTeams(teams) {
      const team = [] || undefined;

      for (let i = 0; i < teams.length; i++) {
        // Get the project id and push to container
        team.push(teams[i].id);
      }

      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ team } },
      });
    },

    // Reset the page filters
    resetFilter() {
      this.$router.push({ 
        name: 'assignmentSheet',
        query: { perPage: undefined, page: undefined }
      });
      
      // Reset filter project
      this.$refs.filterProject.reset();
      // Reset filter member
      this.$refs.filterMember.reset();
      // Reset filter team
      this.$refs.filterTeam.reset();
    },
  },
};
</script>

<style lang="scss">
.assignment{
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color:white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
}

.filter-container {
  position: fixed;
  z-index: 1;
}
table, th {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.v-input--selection-controls{
  margin-top: 0px !important;
}

.v-input input{
  min-height: 32px;
  max-height: none;
}

.v-select.v-select--chips .v-select__selections{
  min-height: 36px;
}
.v-chip.v-size--default {
  height: 24px;
  font-size: 12px;
}
</style>
