/*
 * In vue file, you can refer from like $t('general.auth.email')
 *     - Defined in node_modules/vue-i18n/types/index.d.ts > VueI18n class
 */
const ja = {
  localeLabel: '日本語',
  general: {
    auth: {
      welcome: 'ようこそ',
      signInMessage: 'アカウントにサインイン',
      email: 'メールアドレス',
      username: 'ユーザー名',
      password: 'パスワード',
      showPassword: 'パスワード表示',
      rememberMe: 'ログイン状態を保持する',
      login: 'ログイン',
      adminLogin: 'Adminログイン',
      logout: 'ログアウト',
      register: '登録',
    },
    user: {
      firstName: '姓',
      lastName: '名',
      fullName: '名前',
      profPic: 'プロフィール画像',
    },
    customer: {
      customer: '顧客',
      list: {
        pageTitle: '顧客 ‐ リスト',
        metaTitle: '顧客 ‐ リスト',
      },
      edit: {
        pageTitle: '顧客 - 編集',
        metaTitle: '顧客 - 編集',
      },
      create: {
        pageTitle: '顧客 ‐ 新規作成',
        metaTitle: '顧客 ‐ 新規作成',
      },
      message: {
        nameRequired: '顧客は必須項目です',
      },
    },
    project: {
      type: 'タイプ',
      name: 'プロジェクト名',
      customer: '顧客名',
      customers: '顧客名',
      color: '色',
      customerValidation: '顧客名を選択してください',
      projectCreate: 'プロジェクト - 新規作成',
      projectEdit: 'プロジェクト - 編集',
      list: {
        pageTitle: 'プロジェクト - リスト',
        metaTitle: 'プロジェクト ‐ リスト',
      },
      edit: {
        pageTitle: 'プロジェクト - 編集',
        metaTitle: 'プロジェクト - 編集',
      },
      create: {
        pageTitle: 'プロジェクト - 新規作成',
        metaTitle: 'プロジェクト - 新規作成',
      },
      projectNameValidation: 'プロジェクト名を入力してください',
      projectNameMessage: 'IDNチームによる識別のため、常に英語表現を含めてください。e.g. 開日ポータル(Kaibi-portal)',
      typeValidation: 'タイププロジェクトを入力してください',
    },
    member: {
      list: {
        pageTitle: 'メンバー - リスト',
        metaTitle: 'メンバー - リスト',
      },
      create: {
        pageTitle: 'メンバー - 新規作成',
        metaTitle: 'メンバー - 新規作成',
      },
      edit: {
        pageTitle: 'メンバー - 編集',
        metaTitle: 'メンバー - 編集',
      },
      team: 'チーム',
      name: '表示名',
      username: 'ユーザー名',
      password: 'パスワード',
      loginInfo: 'ログイン情報',
      defaultPasswordButton: '変更',
      pressedPasswordButton: 'キャンセル',
      role: '役割',
      permission: '許可',
      status: 'ステータス',
      submit: '登録する',
      message: {
        teamRequired: 'チームは必須項目です',
        nameRequired: '名前は必須項目です',
        nameMaxCharacters: '名前は25文字以下で入力してください',
        nameDuplicateWarning: '警告: この名前は既に使用されています',
        usernameRequired: 'ユーザー名は必須項目です',
        usernameMinCharacters: 'ユーザー名は4文字以上で入力してください',
        usernameMaxCharacters: 'ユーザー名は25文字以下で入力してください',
        usernameAlphanumeric: 'ユーザー名は半角英数字か「._-」で入力してください',
        usernameDuplicate: 'このユーザー名は既に使用されています',
        passwordRequired: 'パスワードは必須項目です',
        passwordMinCharacters: 'パスワードは12文字以上で入力してください',
        passwordMaxCharacters: 'パスワードは25文字以下で入力してください',
        passwordAlphanumeric: 'パスワードは半角英数字で入力してください',
        passwordLowerCase: 'パスワードに小文字を1文字以上入力してください',
        passwordUpperCase: 'パスワードに大文字を1文字以上入力してください',
        passwordNumber: 'パスワードは最低一文字数字を入力してください',
        passwordSpecialCharacter: 'パスワードは最低一文字記号を入力してください',
        roleRequired: '役割は必須項目です',
        permissionRequired: '許可は必須項目です',
        duplicateChecking: '重複チェック中です...',
      },
    },
    modificationHistory: {
      list: {
        pageTitle: '変更履歴',
      },
      date: '日時',
      type: 'タイプ',
      beforeUpdate: '更新前',
      afterUpdate: '更新後',
      project: 'プロジェクト',
      order: '発注',
      pm: 'PM',
      updatedBy: '変更者',
      invoiceAmount: '金額(税別)',
      InvoiceDate: '請求日',
    },
    role: {
      role: '役割',
      admin: '管理者',
      user: 'ユーザー',
    },
    invoice: {
      list: {
        pageTitle: '経営 - 発注一覧',
        metaTitle: '経営 ‐ 発注一覧',
      },
      name: '発注',
      pm: 'PM',
      member: 'プロジェクトメンバー',
      status: '発注状況',
      all: 'すべて',
      invoiceMonth: '請求月',
      paymentDeadlineMonth: '支払期限',
      paymentAmount: '現金（税別）',
      amount: '金額 (税別)',
      invoiceDate: '請求日',
      paymentDeadline: '支払期限',
      acceptanceDate: '検収日',
      paymentDate: '支払日',
      yen: '円',
      purchaseOrderUrl: '発',
      invoiceUrl: '請',
      amountTax: '金額 (税込)',
    },
    projectReport: {
      list: {
        pageTitle: '経営 - 利益',
        metaTitle: '経営 - 利益',
      },
      profit: '利益（税別）',
      profitRate: '利益率',
      indCost: 'IDNコスト',
      japanCost: 'JPコスト',
      outsourcingCost: '外注費',
    },
    PMS: {
      list: {
        pageTitle: '経営 - 売上・利益(PM)',
        metaTitle: '経営 - 売上・利益(PM)',
      },
      term: '期間',
      pm: 'PM',
      sales: '売上',
      cost: 'Cost',
      profit: '利益',
    },
    assignmentSheet: {
      project: 'プロジェクト',
      member: 'メンバー',
      teams: 'チーム',
      dialogAdd: {
        title: 'アサイメント 追加',
        startDate: '開始日',
        endDate: '終了日',
        workingHourPerDay: '１日あたりの作業時間',
        weekend: '週末',
        note: '説明',
        validation: {
          required: 'この項目は入力必須です。',
        },
      },
      schedules: 'スケジュール',
      assignments: 'アサインメント',
      dailyReports: '日報',
      morningReports: '朝報',
    },
    schedule: {
      createDailyReport: '新規日報作成',
      createMorningReport: '新規朝報作成',
      cancel: 'キャンセル',
      addEvent: 'イベント - 追加',
      editEvent: 'イベント - 編集',
      title: 'タイトル*',
      project: 'プロジェクト',
      order: 'オーダー',
      description: '説明',
      close: '閉じる',
      keep: '保存',
      update: '更新',
      allDay: '全ての日付',
      member: 'メンバー',
    },
    validation: {
      required: '必須',
      optional: '任意',
      confirm: '確認',
      greaterThan: '下限より大きくする必要があります',
      lessThan: '上限未満である必要があります',
      positiveInteger: '正の整数を入力してください',
      cancel: 'キャンセル',
      projectExists: 'このプロジェクトは既に作成されています',
      dailyReportExists: '日報は既存です',
      morningReportExists: '朝報は既存です',
      noEventExists: 'その日付にイベントはないです',
    },
    hint: {
      partial: '部分一致',
    },
    demo: {
      dummyMeetings: '打ち合わせ',
    },
    form: {
      reset: '検索条件をリセットする',
      checkboxReset: 'チェックを外す',
      search: '検索する',
      displayedResults: '表示件数',
    },
    filter: {
      reset: '検索条件のリセット',
      user: 'ユーザー名',
      name: '氏名',
      customer: '顧客名',
      noItem: '選択可能なデータがありません。',
    },
    crud: {
      createNew: '新規作成',
      list: '一覧',
      add: '新規作成',
      edit: '編集',
      update: '更新',
      change: '変更',
      delete: '削除',
      search: '検索',
      toggleTotals: {
        header: 'テーブル列の表示設定',
        label: '合計列表示',
      },
      loading: '読み込み中...',
      all: '全部',
      minPlaceholder: '下限なし',
      maxPlaceholder: '上限なし',
      createdAt: '作成日',
      updatedAt: '更新日',
      action: 'アクション',
      total: '合計',
      filterColumn: 'テーブル列の表示設定',
      btn: {
        edit: '編集',
        duplicate: '翌月分を作成',
        delete: '削除',
      },
      reset: 'リセット',
      editMode: '編集モード',
    },
    time: {
      date: '日付',
      time: '時間',
    },
    nav: {
      projectName: 'プロジェクト管理システム',
      grune: 'Grune',
      dashboard: 'ダッシュボード',
      users: 'ユーザー',
      dailyReports: '日報',
      morningReports: '朝報',
      orders: '発注',
      management: {
        management: '経営',
        modificationHistory: '変更履歴',
        projects: 'プロジェクト',
        profits: '利益',
        combined: '発注一覧 (統合検索)',
        dailyReports: '稼働時間(人)',
      },
      invoices: '発注一覧',
      sales: '月次売上',
      workingReports: '経営',
      pms: '売上・利益(PM)',
      members: 'メンバー',
      customers: '顧客',
      projects: 'プロジェクト',
      unitPrices: '単価',
      workingHours: '稼働時間(発注)',
      assignmentSheets: 'アサインメント・シート',
      schedules: 'スケジュール',
    },
    placeholder: {
      email: 'sample@email.com',
      username: 'rei_665',
      firstName: '山下',
      lastName: '太郎',
      fullName: '山下太郎',
    },
    alert: {
      youSure: '削除確認',
      confirmDelete: '選択したデータを削除します。削除されたデータは元に戻せません。',
      selectDate: '日付を選択してください',
    },
    order: {
      contract: '請負',
      labo: 'ラボ型',
      is_active: 'ステータス',
      active: 'アクティブ',
      unactive: '停止',
      projects: 'プロジェクト',
      projectName: 'プロジェクト名',
      type: 'タイプ',
      customer: '顧客名',
      orders: '発注',
      offshore: 'オフショア',
      orderName: '発注名',
      jp: '日本語',
      en: '英語',
      status: '発注状況',
      members: 'メンバー',
      memberJpn: 'JP',
      memberIdn: 'IDN',
      pm: 'PM：',
      invoicingInfomation: '請求情報',
      invoiceAmount: '金額(税別)',
      invoiceAmountWithTax: '金額(税込)',
      manuallyInputPriceWithTax: '税込み価格を手動で入力する',
      invoiceAmountWithTaxWarning: '税込み価格はFreeeの請求書と一致する必要があります。一致しない場合は手動で入力してください。',
      yen: '円',
      invoiceDate: '請求日',
      paymentDeadline: '支払期限',
      paymentDate: '支払日',
      purchaseOrderUrl: '発注書URL',
      invoiceUrl: '請求書URL',
      outsourcingCost: '外注費',
      itemName: '項目名',
      paymentAmount: '金額(税別)',
      add: '追加',
      remarks: '備考',
      submit: '登録する',
      edit: {
        pageTitle: '発注/請求書 - 編集',
        metaTitle: '発注/請求書 - 編集',
      },
      create: {
        pageTitle: '発注/請求書 - 新規作成',
        metaTitle: '発注/請求書 - 新規作成',
      },
      message: {
        projectNameRequired: 'プロジェクト名は必須項目です',
        jpRequired: '発注名(日)は必須項目です',
        enRequired: '発注名(英)は必須項目です',
        statusRequired: '状況は必須項目です',
        acceptedDateRequired: '受理日が必要です',
        pmRequired: 'PMは必須項目です',
        invoiceAmountRequired: '金額(税別)は必須項目です',
        invoiceDateRequired: '請求日は必須項目です',
        paymentDeadlineRequired: '支払期限は必須項目です',
        purchaseOrderUrlRequired: '発注書URLは必須項目です',
        invoiceUrlRequired: '請求書URLは必須項目です',
        itemNameRequired: '項目名は必須項目です',
        paymentAmountRequired: '金額(税別)は必須項目です',
        paymentDateRequired: '支払日は必須項目です',
        jpMinCharacters: '発注名(日)は3文字以上で入力してください',
        enMinCharacters: '発注名(英)は8字以上で入力してください',
        itemNameMinCharacters: '項目名は1字以上で入力してください',
        invoiceAmountMinCharacters: '金額は1円以上で入力してください',
        purchaseOrderUrlCheck: '発注書URLにはURLを入力してください',
        invoiceUrlCheck: '請求書URLにはURLを入力してください',
        remarksMaxCharacters: '備考は510文字以下で入力してください',
        duplicateMember: 'メンバーが重複しています。別のメンバーを選択してください',
        projectNameCheck: 'プロジェクトを選択してください',
      },
      issuer: '発行者',
      officeWork: '事務',
    },
    unit_price: {
      pageTitle: '単価 - リスト',
      team: 'チーム',
      role: '役割',
      price: '単価',
      edit: '編集',
      cancel: 'キャンセル',
      save: '保存する',
    },
    reports: {
      remaining: '残り',
      letter: '文字',
      saveDraft: '下書きを保存',
      loadDraft: '下書きを読み込む',
      list: {
        pageTitle: '日報 - リスト',
        metaTitle: '日報 - リスト',
      },
      create: {
        pageTitle: '日報 - 新規作成',
        metaTitle: '日報 - 新規作成',
      },
      edit: {
        pageTitle: '日報 - 編集',
        metaTitle: '日報 - 編集',
      },
      add: '追加',
      condition: '検索条件',
      date: '日付',
      projectName: 'プロジェクト名',
      orderName: '発注',
      JP: '日',
      EN: '英',
      result: '検索結果',
      total: '合計労働時間',
      time: '作業時間',
      progress: '進捗',
      message: {
        progressLength: '2000文字以内で入力してください',
        start_date: {
          greaterThanToday: '開始日は今日以前の日付に設定してください',
          greaterThanDateEnd: '開始日は終了日以前の日付に設定してください',
        },
        end_date: {
          greaterThanToday: '終了日は今日以前の日付に設定してください',
          lessThanDateStart: '終了日は開始日以降の日付に設定してください',
        },
        dateRequired: '日付を選択してください',
        dateLesserThanToday: '日付は今日以前の日付に設定してください',
        projectNameRequired: 'プロジェクト名を選択してください',
        orderNameJPRequired: '発注名（日本語）を選択してください',
        orderNameENRequired: '発注名（英語）を選択してください',
        timeRequired: '作業時間を選択してください',
        progressRequired: '進捗を入力してください',
      },
      projectNotAvailableGuidance:
        '※ プロジェクトが見つからない場合、現在の受注に参加させてもらうようPMに依頼してください。',
      totalWorkingTime: '総勤務時間',
      close: '閉じる',
      save: '保存',
      addOrder: '発注 - 追加',
      newProjectName: 'プロジェクト',
      newOrderName: 'オーダー',
      addNewOrder: '新規オーダーを追加',
    },
    morningReports: {
      remaining: '残り',
      letter: '文字',
      saveDraft: '下書きを保存',
      loadDraft: '下書きを読み込む',
      list: {
        pageTitle: '朝報 - リスト',
        metaTitle: '朝報 - リスト',
      },
      create: {
        pageTitle: '朝報 - 新規作成',
        metaTitle: '朝報 - 新規作成',
      },
      edit: {
        pageTitle: '朝報 - 編集',
        metaTitle: '朝報 - 編集',
      },
      add: '追加',
      condition: '検索条件',
      date: '日付',
      projectName: 'プロジェクト名',
      orderName: '発注',
      JP: '日',
      EN: '英',
      result: '検索結果',
      total: '合計労働時間',
      time: '作業時間',
      progress: '進捗',
      message: {
        progressLength: '2000文字以内で入力してください',
        start_date: {
          greaterThanToday: '開始日は今日以前の日付に設定してください',
          greaterThanDateEnd: '開始日は終了日以前の日付に設定してください',
        },
        end_date: {
          greaterThanToday: '終了日は今日以前の日付に設定してください',
          lessThanDateStart: '終了日は開始日以降の日付に設定してください',
        },
        dateRequired: '日付を選択してください',
        dateLesserThanToday: '日付は今日以前の日付に設定してください',
        projectNameRequired: 'プロジェクト名を選択してください',
        orderNameJPRequired: '発注名（日本語）を選択してください',
        orderNameENRequired: '発注名（英語）を選択してください',
        timeRequired: '作業時間を選択してください',
        progressRequired: '進捗を入力してください',
      },
      projectNotAvailableGuidance:
        '※ プロジェクトが見つからない場合、現在の受注に参加させてもらうようPMに依頼してください。',
      totalWorkingTime: '総勤務時間',
      close: '閉じる',
      save: '保存',
      addOrder: '発注 - 追加',
      newProjectName: 'プロジェクト',
      newOrderName: 'オーダー',
      addNewOrder: '新規オーダーを追加',
    },
    sales: {
      list: {
        pageTitle: '経営 - 月次売上',
        metaTitle: '経営 - 月次売上',
      },
      placeholder: {
        lowerMonth: '～から',
        upperMonth: '～まで',
      },
      status: '請求書ステータス',
      all: 'すべて',
      invoiceMonth: '期間',
      amount: '売上',
      amountPotentialHigh: '売上+見込高',
      amountPotentialModerate: '売上+見込中',
      amountPotentialLow: '売上+見込低',
      yen: '円',
    },
    workingreport: {
      number: '番号',
      list: {
        pageTitle: '経営 - 稼働時間(人)',
        metaTitle: '経営 - 稼働時間(人)',
      },
      placeholder: {
        lowerMonth: '～から',
        upperMonth: '～まで',
      },
      term: '期間',
      team: 'チーム',
      name: '名前',
      role: '役割',
      time: '作業時間',
      detail: '詳細',
      totalReports: '総報告数',
    },
    morningReportManagement: {
      list: {
        pageTitle: '経営 - 朝報',
        metaTitle: '経営 - 朝報',
      },
      placeholder: {
        lowerMonth: '～から',
        upperMonth: '～まで',
      },
      term: '期間',
      team: 'チーム',
      name: '名前',
      role: '役割',
      time: '作業時間',
      detail: '詳細',
    },
    workingHour: {
      jpnHour: 'JP作業時間',
      idnHour: 'IDN作業時間',
      totalCost: '総費用',
      targetMonth: '集計対象月',
      list: {
        pageTitle: '経営 - 稼働時間(発注)',
        metaTitle: '経営 - 稼働時間(発注)',
      },
    },
    combined: {
      targetMonth: '稼働時間集計対象月',
      list: {
        pageTitle: '経営 - 発注一覧 (統合検索)',
        metaTitle: '経営 - 発注一覧 (統合検索)',
      },
    },
    tooltip: {
      noSelect: '選択なし',
    },
  },
};

export default ja;
