<template>
  <div class="assignment">
    <Breadcrumbs :toLink="'dashboard'">
      <template v-slot:toLink>
        {{ $t('general.nav.dashboard') }}
      </template>
      <template v-slot:pageTitle>
        {{ $t('general.nav.assignmentSheets') }}
      </template>
    </Breadcrumbs>

    <!-- Page Container -->
    <PageInnerSet :title="$t('general.nav.assignmentSheets')">
      <div style="margin-top: -12px">
        <!-- Filter Search -->
        <v-form ref="filter" class="filter-form">
          <PageFilters :filters="$props" />
        </v-form>
        
        <div>
          <v-row class="mx-0 justify-space-between" cols="12">
            <v-col cols="12" md="6" class="px-0">
              <v-row class="align-center no-gutters">
                <v-checkbox color="red" label="assignment" v-model="display.assignment" class="mr-4"></v-checkbox>
                <v-checkbox color="orange" label="schedule" v-model="display.schedule" class="mr-4"></v-checkbox>
                <v-checkbox color="success" label="daily reports" v-model="display.dailyReport" class="mr-4"></v-checkbox>
                <v-checkbox color="amber" label="morning reports" v-model="display.morningReport"></v-checkbox>
              </v-row>
            </v-col>

            <v-col cols="12" md="auto">
              <div class="sorting-perpage">
                <FilterSelectBox
                  ref="filterDisplayResult"
                  :items="['5', '10', '20', 'All']"
                  v-model="pagination.perPage"
                  :title="$t('general.form.displayedResults')"
                  @input="onPerPageChange"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- Pagination -->
        <CalendarPagination :startDate="startDate" :endDate="endDate" :loading="isLoading" />
      </div>
      <!-- main  -->
      <div class="table-container" style="width: 100%;">
        <MaskLoader :loading="isLoading" />
        <Table
          :headers="result.headers"
          :members="result.members"
          :reports="result.reports"
          :morningReports="result.morningReports"
          :schedules="result.schedules"
          :assignments="result.assignments"
          :loading="isLoading"
          :drawerOpen="drawerOpen"
          :display="display"
          :assignmentDialog="openAssignmentDialog"
          @assignment-form="handleAssignmentForm"
        />
      </div>

      <div class="table-footer my-4">
        <v-pagination
          v-if="result.pagination.totalPages > 1"
          v-model="pagination.page"
          :length="result.pagination.totalPages"
          @input="onPageChange">
        </v-pagination>
      </div>

      <AssignmentDialogVue
        :dialogOpen.sync="dialogAssignment"
        :selectedMember.sync="selectMemberAssignment"
        :members="selectBox.members"
        :projects="selectBox.projects"
        :duration="selectBox.durationTimes"
        @close-dialog="dialogAssignment = false"
        :assignmentForm="assignmentForm"
      />
    </PageInnerSet>
  </div>
</template>

<script>
import { pickBy, isUndefined } from 'lodash';
import Bus from '@helpers/bus';
import { getAll } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import MaskLoader from '@/views/_components/page/MaskLoader';
import PageFilters from './components/filters/PageFilters';
import Table from './components/table/Table.vue';
import Breadcrumbs from '@views/_components/organisms/Breadcrumbs.vue';
import CalendarPagination from '@views/_components/molecules/calender/CalendarPagination.vue';
import AssignmentDialogVue from '../../_components/molecules/dialogs/AssignmentDialog.vue';
import FilterSelectBox from '@views/_components/datatable_filter/TableFilterSelectBox';

export default {
  metaInfo() {
    return {
      title: 'Assignment Sheet',
    };
  },

  components: {
    Table,
    MaskLoader,
    PageInnerSet,
    PageFilters,
    Breadcrumbs,
    CalendarPagination,
    AssignmentDialogVue,
    FilterSelectBox
  },

  props: {
    startDate: { type: String, default: undefined },
    endDate: { type: String, default: undefined },
    project: { type: [Number, String, Array], default: undefined },
    member: { type: [Number, String, Array], default: undefined },
    team: { type: [Number, String, Array], default: undefined },
  },

  data: function() {
    return {
      isLoading: false,
      drawerOpen: true,
      dialogAssignment: false,
      selectMemberAssignment: 0,
      display: {
        assignment: true,
        schedule: true,
        dailyReport: true,
        morningReport: true,
      },
      pagination: {
        perPage: '5',
        page: 1,
      },
      result: {
        headers: [],
        members: [],
        teams: [],
        reports: [],
        schedules: [],
        assignments: [],
        pagination: {}
      },
      selectBox: {
        members: [],
        projects: [],
        teams: [],
        durationTimes: [],
      },
      assignmentForm: {
        start_date: '',
        end_date: '',
        duration_in_hours: [],
        is_weekend: false,
        member_id: [],
        project_id: [],
        note: '',
      },
    };
  },

  created() {
    Bus.$on('triggerNavOpen', event => {
      this.drawerOpen = event;
    });
    this.getSelectBoxData();
    this.setSelectBoxDuration();
  },

  computed: {
    // Collection of filter that doesn't have undefined value
    filters() {
      const filters = pickBy(this.$props, function (value) {
        return !isUndefined(value);
      });

      // Get perPage and page values from query, fallback to default if undefined
      const perPage = this.$route.query.perPage || '5';  // Default perPage = 5
      const page = this.$route.query.page || 1;        // Default page = 1

      // Return filters including perPage and page
      return {
        ...filters,
        perPage: perPage,
        page: page,
      };
    },

    // Start date moment
    $startDate() {
      return this.$moment(this.startDate);
    },

    // End date moment
    $endDate() {
      return this.$moment(this.endDate);
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const format = 'YYYY-MM-DD';

        // Calculate default start or end
        const defaultStartDate = this.$moment()
          .subtract(14, 'days')
          .format(format);

        const defaultEndDate = this.$moment()
          .add(14, 'days')
          .format(format);

        // Date filters
        const startDate = this.$props.startDate ?? defaultStartDate;
        const endDate = this.$props.endDate ?? defaultEndDate;

        // Update pagination.page from query
        const page = parseInt(to.query.page, 10) || 1; // Default to page 1
        this.pagination.page = page;

        // Update pagination.perPage from query
        let perPage = (to.query.perPage || '5').toString(); // Default to '5' and convert to string
        this.pagination.perPage = perPage;

        // If start or end date is undefined,
        // navigate to the default start and end
        if (!this.$props.startDate || !this.$props.endDate) {
          const query = { ...to.query, ...{ startDate, endDate } };
          this.$router.push({ name: 'assignmentSheet', query });
          return;
        }

        // Fetch the server data
        this.fetchData();
      },
    },
    dialogAssignment: {
      immediate: true,
      handler(newValue, oldValue) {
        if (oldValue === true && newValue === false) {
          this.fetchData();
        }
      },
    },
  },

  methods: {
    async fetchData() {
      // Set loading state
      this.isLoading = true;

      try {
        // Do the request
        const url = '/assignment-sheet/filter';
        const response = await getAll(url, this.filters);
        // Update the result
        this.result.headers = response.dates ?? [];
        this.result.members = response.members ?? [];
        this.result.reports = response.reports ?? [];
        this.result.morningReports = response.morningReports ?? [];
        this.result.schedules = response.formattedSchedules ?? [];
        this.result.assignments = response.formattedAssignments ?? [];
        this.result.pagination = response.pagination ?? null;
      } catch (error) {
        console.log(error);
      }

      // Reset loading state
      this.isLoading = false;
    },

    getHolidayDate: async function() {
      const BASE_CALENDAR_URL = 'https://www.googleapis.com/calendar/v3/calendars';
      const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = 'holiday@group.v.calendar.google.com'; // Calendar Id. This is public but apparently not documented anywhere officialy.
      const API_KEY = 'AIzaSyB6XzGg9Mi1dKbFITQ0WSFubVEvYRRnZbk';
      const CALENDAR_REGION = 'en.usa'; // This variable refers to region whose holidays do we need to fetch
      const url = `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}`;

      try {
        await getAll(url, { crossDomain: true });
      } catch (error) {
        console.log(error);
      }
    },

    openAssignmentDialog(value) {
      this.dialogAssignment = true;
      this.selectMemberAssignment = value;
    },

    getSelectBoxData: async function() {
      let url = '/assignment-sheet/select-box';
      try {
        const res = await getAll(url);
        this.selectBox.projects = [...res.project];
        this.selectBox.members = [...res.member];
        this.selectBox.teams = [...res.teams];
      } catch (error) {
        console.log(error);
      }
    },

    setSelectBoxDuration() {
      const baseTime = 0.5;
      const time = 8 / baseTime;
      const durationTimes = [];

      for (let index = 1; index <= time; index++) {
        durationTimes.push(parseFloat((index * baseTime).toFixed(2)));
      }
      this.selectBox.durationTimes = durationTimes;
    },

    handleAssignmentForm(assignmentForm) {
      // Pass the assignmentForm values up to the Assignment Dialog component
      this.assignmentForm = assignmentForm;
    },

    onPerPageChange(page) {
      // Define the perPage as container
      const perPage = [] || undefined;

      // Set the per page
      perPage.push(page);

      // Push to the router
      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ page: 1, perPage } },
      });
    },

    onPageChange(currentPage) {
      // Define the perPage as container
      const page = [] || undefined;

      // Set the per page
      page.push(currentPage);

      // Push to the router
      this.$router.push({
        name: 'assignmentSheet',
        query: { ...this.$route.query, ...{ page } },
      });
    }
  },
};
</script>

<style lang="scss">
.assignment {
  .table-container {
    position: relative;
    min-height: 10rem;
    z-index: 2;
  }
  .filter-form {
    position: relative;
    width: auto;
  }

  .sorting-perpage {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 3rem;

    .center_element{
      max-width: 200px;

      .col-md-2{
        width: 100px;
        padding-bottom: 0;

        @media screen and (min-width: 768px) {
          min-width: 100px;
        }
      }
      .col-md-10{
        max-width: 100px;

        .v-text-field__details{
          display: none;
        }
      }
    }
  }
}
</style>
