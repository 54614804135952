<template>
  <div class="customer">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{$t("general.nav.dashboard")}}</router-link>
      <span class="mx-2">></span>
      <span>{{$t("general.customer.list.pageTitle")}}</span>
    </p>
    <!-- Page container -->
    <PageInnerSet :title="$t('general.customer.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <!-- <FilterReset @click="resetFilter"></FilterReset> -->

        <!-- customer name filter -->
        <v-row cols="12">
          <v-col cols="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <FilterTextSelect
                      :title="$t('general.customer.customer')+'：'"
                      :title_width= "4"
                      :items="selectBoxData.names"
                      v-bind="attrs"
                      v-model="searchingFormData.name"
                      @input="updateAllFilters"
                    />
                  </div>
              </template>
              <span>{{ searchingFormData.name ? tooltipItem(searchingFormData.name) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>

      </v-form>

      <div class="sorting-perpage">
        <FilterSelectBox
          :items="[50, 100, 200, 500]"
          v-model="options.itemsPerPage"
          title="Rows per page :"
          @input="handlePageSizeChange"
        />
      </div>

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="customers"
        :options.sync="options"
        :page.sync="currentPage"
        :items-per-page="options.itemsPerPage"
        :server-items-length="totalCustomers"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        hide-default-footer
        @page-count="totalPages = $event"
        fixed-header
        @update:options="handlePaginationChange"
      >
        <!--
          <template v-slot:item.project_type_id="{ item }">
          {{ item.project_type.name_jp }}
        </template>
        -->


        <!--Should only show this button to main admin -->
        <template v-if="this.isMainAdmin()" v-slot:item.action="{ item }">
          <v-btn
            :disabled="isLoadingTableData"
            :to="{ name: 'customers.edit', params: { id: item.id} }"
            color="primary"
            class="mx-2 white--text"
            x-small
          >{{ $t('general.crud.edit') }}</v-btn>
        </template>

      </v-data-table>

      <!-- Pagination Controls -->
      <div class="pt-5">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="7"
          @input="handlePageChange"
        />
      </div>

    </PageInnerSet>
  </div>
</template>

<script>
/**
 * 29 June 2021 documented by Satoshi Sugai
 *
 * How searching form works
 * 1. As soon as one of the searching form items gets input, run "updateAllFilters()" method runs
 * 2. Inside "updateAllFilters()", "updateFilter()" method gets called. It sets/clears filter("activeFilters")) to be sent to api
 * 3. Once "activeFilters" gets changed, retrieve list data from api again. This happens also when reset text gets pressed.
 *
 * How pagination works
 * - While you are in say page 2 and use a filter, the page will be automatically set to 1.
 *
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 *
 */

/* July 1st 2021 documented by Tomas Sullivan
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 *
 */

import { getAll, getSelectBoxData } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';

import FilterReset from '@views/_components/datatable_filter/TableFilterReset';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import FilterSelectBox from '@views/_components/datatable_filter/TableFilterSelectBox';
//import Pagination from '@views/_components/Page/Pagination';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.customer.list')
    }
  },
  components: {
    // FilterReset,
    FilterTextSelect,
    PageInnerSet,
    FilterSelectBox,
  },
  data: function() {
    return {
      customers: [],
      totalCustomers: 0,
      options: {
        itemsPerPage: parseInt(localStorage.getItem('customers-items-per-page')) || 100,
        page: 1,
        multiSort: false,
        sortBy: [],
        sortDesc: [],
      },
      searchingFormData: {
        name: '',
      },
      activeFilters: {},
      selectBoxData: {
        names: [],
      },
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false,   // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
    }
  },
  mounted: function() {
    this.getAllCustomers();
    this.retrieveSelectBoxData();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler(){
        this.updateDataTable();
      },
      deep: true
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.customer.customer'),
          value: 'name',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
        },
      ];
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    // Function for edit button being displayed
    isMainAdmin: function() {
      console.log("this.$store.state.auth.info.role = " + this.$store.state.auth.info.role);
      console.log("this.$store.state.global.constants.permissions.adminString = " + this.$store.state.global.constants.permissions.adminString);
      return this.$store.state.auth.info.role === this.$store.state.global.constants.permissions.adminString;
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of cusotmers
     * - sets retrieved data to corresponding data
     */
    getAllCustomers: async function() {
      let url = 'customers'
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of customers from api
      try {
        //this request will be converted to $params on the API side.
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.customers = res.customers;
        this.totalCustomers = res.total;
        this.totalPages = Math.ceil(res.total / itemsPerPage);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber=false) {
      this.getAllCustomers();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      this.$refs.filter.reset();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('name', this.searchingFormData.name);
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * Val is an array. If passed to Laravel api, then it will become a string, separated by ","
     * We can use explode to separate by comma, but if the substring has commas in it that will not work properly.
     * Therefore I will join with a rare set of characters "||"
     * In other pages, not all of our filters are arrays so I recommend to always check if the value is an array or not
     */
    updateFilter: function(attribute, value) {
      if (value) {
        if (Array.isArray(value)){
        value = value.join("||");
        }
        this.$set(this.activeFilters, attribute, value);
      } else {
        this.$delete(this.activeFilters, attribute);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/customers/select-box'
      console.log("url = " + url)
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        console.log("res.names = " + res.names);
        this.selectBoxData.names = res.names.map(item => item.name);
        console.log(this.selectBoxData.names);
      } catch (err) {
        console.log("err = " + err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },

    handlePaginationChange(newOptions) {
      this.options = newOptions;
      this.getAllCustomers();
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.options.page = newPage;
      this.getAllCustomers();
    },
    handlePageSizeChange(newSize) {
      this.options.itemsPerPage = newSize;
      localStorage.setItem('customers-items-per-page', newSize);
      this.currentPage = 1; // Reset to the first page
      this.options.page = 1; // Ensure options reflect the reset
      this.getAllCustomers();
    },
  },
}
</script>

<style lang="scss">
.customer {
  .v-data-table{
    height: auto !important;
  }
  .sorting-perpage{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 15px;
    padding-bottom: 16px;

    .center_element{
      max-width: 200px;

      .col-md-2{
        width: 100px;
        padding-bottom: 0;

        @media screen and (min-width: 768px) {
          min-width: 100px;
        }
      }
      .col-md-10{
        max-width: 100px;

        .v-text-field__details{
          display: none;
        }
      }
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color:white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  .v-chip {
    overflow: initial;
    font-size: 11px !important;
  }
}
.v-input__control {
  .v-input__slot {
    padding-bottom: 5px !important;
  }
}
</style>
