<template>
  <div class="modification_history">
    <!-- Breadcrumbs -->
    <Breadcrumbs :toLink="'dashboard'">
      <template v-slot:toLink>
        {{ $t('general.nav.dashboard') }}
      </template>
      <template v-slot:pageTitle>
        {{ $t('general.modificationHistory.list.pageTitle') }}
      </template>
    </Breadcrumbs>
    <!-- Breadcrumbs -->
    <!-- page container  -->
    <PageInnerSet :title="$t('general.modificationHistory.list.pageTitle')">
      <!--Column Filter-->
      <v-row class="mb-1">
        <DateSearch :col_cols="4" :activeFilters="activeFilters" :rules="rules" :DateType="'month'"></DateSearch>
        <v-col cols="2"> </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <ResetButton @resetFilter="resetFilter">{{ $t('general.crud.reset') }}</ResetButton>
          <ColumnFilterModal
            :title="this.$t('general.crud.filterColumn')"
            :openButton="this.$t('general.crud.filterColumn')">
            <v-row>
              <v-col cols="3" v-for="item in headersForSelectBox" :key="item.value">
                <v-checkbox v-model="visibleColumns" :label="item.text" :value="item.value" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </ColumnFilterModal>
        </v-col>
      </v-row>

      <!-- data table -->
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="logs.data"
            :loading="isLoadingTableData"
            :loading-text="$t('general.crud.loading')"
            :options.sync="options"
            :server-items-length="logs.total"
            dense
            fixed-header
            :footer-props="footerProps">
            <!-- select boxes -->
            <template v-slot:[`body.prepend`]>
              <tr>
                <!-- date -->
                <td v-show="isEnabledColumn('date')">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <div multiple v-on="on">
                        <v-combobox
                          :items="selectBoxData.createdAt"
                          :item-value="'id'"
                          v-bind="attrs"
                          v-model="filterData.createdAt"
                          @input="updateAllFilters"
                          chips
                          deletable-chips
                          dense
                          multiple
                          style="width: 110px">
                        </v-combobox>
                      </div>
                    </template>
                    <span>{{filterData.createdAt ? tooltipItem(filterData.createdAt) : $t('general.tooltip.noSelect')}}</span>
                  </v-tooltip>
                </td>
                <!-- type -->
                <td v-show="isEnabledColumn('type')">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <div multiple v-on="on">
                        <v-combobox
                          :items="selectBoxData.logTypes"
                          :item-text="isLocaleJapanese ? 'name_jp': 'name_en'"
                          :item-value="'id'"
                          v-bind="attrs"
                          v-model="filterData.logTypeId"
                          @input="updateAllFilters"
                          chips
                          deletable-chips
                          dense
                          multiple
                          style="width: 110px">
                        </v-combobox>
                      </div>
                    </template>
                    <span>{{filterData.logTypeId ? tooltipItem(filterData.logTypeId) : $t('general.tooltip.noSelect')}}</span>
                  </v-tooltip>
                </td>
                <!-- project -->
                <td v-show="isEnabledColumn('project')">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <div multiple v-on="on">
                        <v-combobox
                          :items="selectBoxData.projectNames"
                          :item-text="'name'"
                          :item-value="'id'"
                          v-bind="attrs"
                          v-model="filterData.projectName"
                          @input="updateAllFilters"
                          chips
                          deletable-chips
                          dense
                          multiple
                          style="width: 220px">
                        </v-combobox>
                      </div>
                    </template>
                    <span>{{filterData.projectName ? tooltipItem(filterData.projectName) : $t('general.tooltip.noSelect')}}</span>
                  </v-tooltip>
                </td>
                <!-- order  -->
                <td v-show="isEnabledColumn('order')">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <div multiple v-on="on">
                        <v-combobox
                          :items="selectBoxData.orderNames"
                          :item-text="'name_jp'"
                          :item-value="'id'"
                          v-bind="attrs"
                          v-model="filterData.orderId"
                          @input="updateAllFilters"
                          chips
                          deletable-chips
                          dense
                          multiple
                          style="width: 200px">
                        </v-combobox>
                      </div>
                    </template>
                    <span>{{filterData.orderId ? tooltipItem(filterData.orderId) : $t('general.tooltip.noSelect')}}</span>
                  </v-tooltip>
                </td>
                <!-- PM  -->
                <td v-show="isEnabledColumn('updatedBy')">
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <div multiple v-on="on">
                        <v-combobox
                          :items="selectBoxData.updatedBy"
                          :item-text="'name'"
                          :item-value="'id'"
                          v-bind="attrs"
                          v-model="filterData.updatedBy"
                          @input="updateAllFilters"
                          chips
                          deletable-chips
                          dense
                          multiple
                          style="width: 200px">
                        </v-combobox>
                      </div>
                    </template>
                    <span>{{filterData.updatedBy ? tooltipItem(filterData.updatedBy) : $t('general.tooltip.noSelect')}}</span>
                  </v-tooltip>
                </td>
                <td v-show="isEnabledColumn('invoice_amount')" style="width: 200px;">
                </td>
                <td v-show="isEnabledColumn('invoice_date')" style="width: 200px;">
                </td>
              </tr>
            </template>
            <!-- select boxes -->

            <!-- items -->
            <!-- date 日時 -->
            <template v-slot:[`item.date`]="{ item }">
              <div>
                {{item.created_at}}
              </div>
            </template>
            <!-- type タイプ -->
            <template v-slot:[`item.type`]="{ item }">
              <div>
                {{item.log_type.name_jp ? item.log_type.name_jp : ''}}
              </div>
            </template>
            <!-- project プロジェクト -->
            <template v-slot:[`item.project`]="{ item }">
              <div>
                {{item.project_name ? item.project_name : ''}}
              </div>
            </template>
            <!-- order 発注 -->
            <template v-slot:[`item.order`]="{ item }">
              <div>
                {{item.order ? item.order.name_jp : ''}}
              </div>
            </template>
            <!-- PM -->
            <template v-slot:[`item.updatedBy`]="{ item }">
              <div>
                {{item.member ? item.member.name : ''}}
              </div>
            </template>
            <!-- invoice amount 金額税別 -->
            <template v-slot:[`item.invoice_amount`]="{ item }">
              <div v-if="item.old_invoice_amount">
                <div>
                  {{ $t('general.modificationHistory.beforeUpdate') + ': ' + item.old_invoice_amount }}
                </div>
                <hr/>
                <div>
                  {{$t('general.modificationHistory.afterUpdate') + ': ' + item.modified_invoice_amount}}
                </div>
              </div>
            </template>
            <!-- invoice data 請求日 -->
            <template v-slot:[`item.invoice_date`]="{ item }">
              <div v-if="item.modified_invoice_date">
                <div>
                  {{ $t('general.modificationHistory.beforeUpdate') + ': ' +  item.modified_invoice_date}}
                </div>
                <hr/>
                <div>
                  {{$t('general.modificationHistory.afterUpdate') + ': ' + item.old_invoice_date}}
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </PageInnerSet>
  </div>
</template>

<script>
  import { getAll, getSelectBoxData } from '@services/crud';
  import { formatDateHelper, displayedMoneyHelper, monthYearOnlyHelper, monthYearEnglishHelper } from '@/helpers';
  import PageInnerSet from '@views/_components/page/PageInnerSet';
  import Breadcrumbs from '@views/_components/organisms/Breadcrumbs';
  import ColumnFilterModal from '@views/_components/organisms/filter/ColumnFilterModal';
  import ResetButton from '@views/_components/organisms/button/ResetButton';
  import DateSearch from '@views/_components/organisms/search/DateSearch';

  export default {
    components: {
      PageInnerSet,
      DateSearch,
      Breadcrumbs,
      ResetButton,
      ColumnFilterModal,
    },
    data: function() {
      return {
        activeFilters: {
          start_date: null,
          end_date: null,
        },
        // search data
        filterData: {
          createdAt: this.$route.query.createdAt ? this.$route.query.createdAt: null,
          logTypeId: this.$route.query.logTypeId ? this.$route.query.logTypeId : null,
          projectName: this.$route.query.projectName ? this.$route.query.projectName : '',
          orderId: this.$route.query.orderId ? this.$route.query.orderId: null,
          updatedBy: this.$route.query.updatedBy ? this.$route.query.updatedBy: null,
        },
        // Check if it's loading data from api
        isLoadingTableData: false,
        // main data
        logs: [],
        // a list of which columns's to display
        visibleColumns: [],
        options: {
          itemsPerPage: 100,
          page: 1,
          multiSort: false,
          sortBy: [],
          sortDesc: [],
        },
        footerProps: {
          'items-per-page-options': [100, 200, 500],
        },
        rules: {
          // A || B: this is B if A is not
          start_date: [
            v =>
              this.activeFilters.end_date == null ||
              this.activeFilters.start_date == null ||
              new Date(this.activeFilters.start_date).getTime() <= new Date(this.activeFilters.end_date).getTime() ||
              this.$t('general.reports.message.start_date.greaterThanDateEnd'),
          ],
          end_date: [
            v =>
              this.activeFilters.start_date == null ||
              this.activeFilters.end_date == null ||
              new Date(this.activeFilters.start_date).getTime() <= new Date(this.activeFilters.end_date).getTime() ||
              this.$t('general.reports.message.end_date.lessThanDateStart'),
          ]
        },
        selectBoxData: {
          createdAt: [],
          logTypes: [],
          projectNames: [],
          orderNames: [],
          updatedBy: [],
          invoiceDates: [],
        }
      }
    },
    created() {
      this.visibleColumns = this.allHeaders.map((_element, index) => index);
    },
    mounted() {
      this.updateAllFilters();
      this.getAllData();
      this.retrieveSelectBoxData();
    },
    watch: {
      /**
      * update table if date selected or changed
      */
      activeFilters: {
        handler() {
          this.getAllData();
        },
        deep: true,
      },
      /**
       * Keep an eye on options in the data table (such as sorting, page)
       */
      options: {
        handler() {
          this.updateDataTable();
        },
        deep: true,
      }
    },
    computed: {
      /**
      * header lists
      */
      allHeaders: function() {
        return [
          {
            text: this.$t('general.modificationHistory.date'),
            value: 'date',
            divider: true,
            sortable: true,
          },
          {
            text: this.$t('general.modificationHistory.type'),
            value: 'type',
            divider: true,
            sortable: true,
          },
          {
            text: this.$t('general.modificationHistory.project'),
            value: 'project',
            divider: true,
            sortable: true,
          },
          {
            text: this.$t('general.modificationHistory.order'),
            value: 'order',
            divider: true,
            sortable: true,
          },
          {
            text: this.$t('general.modificationHistory.updatedBy'),
            value: 'updatedBy',
            divider: true,
            sortable: true,
          },
          {
            text: this.$t('general.modificationHistory.invoiceAmount'),
            value: 'invoice_amount',
            divider: true,
            sortable: false,
            width: 150,
          },
          {
            text: this.$t('general.modificationHistory.InvoiceDate'),
            value: 'invoice_date',
            divider: true,
            sortable: false,
            width: 130,
          },
        ];
      },
      headers: function() {
        return this.allHeaders.filter(header => this.visibleColumns.includes(this.allHeaders.indexOf(header)));
      },
      /**
      * header column names
      * text: column name
      */
      headersForSelectBox: function() {
        return this.allHeaders.map(function(element, index) {
          return { value: index, text: element.text };
        });
      },
      isLocaleJapanese: function() {
        return this.$store.state.global.locale === 'ja';
      },
    },
    methods: {
      /**
        *List OF Helper Functions must set first in methods
      */
      monthYearOnlyHelper,
      monthYearEnglishHelper,
      displayedMoneyHelper,
      formatDateHelper,
      /*
        * get all date from Api/LogController@getAll
      */
      getAllData: async function() {
        let url = 'logs';
        console.log("this is options");
        console.log(this.options);
        const { itemsPerPage, page, sortBy, sortDesc } = this.options;
        this.isLoadingTableData = true;

        try {
          const res = await getAll(url, {
            itemsPerPage,
            page,
            sortBy,
            sortDesc,
            ...this.activeFilters,
          });
          this.logs = res;
          // console.log("this is logs data");
          // console.log(this.logs);
          // console.log("this is filter data");
          // console.log(this.filterData)
        }catch(err) {
          console.log(err);
        }finally {
          this.isLoadingTableData = false;
        }
      },
      // for hide select filter if table header is hidden
      isEnabledColumn: function(value) {
        // console.log("this is headers");
        // console.log(this.headers);
        let obj_selectedHeaders = this.headers.find(obj => obj.value == value);
        return obj_selectedHeaders ? true : false;
      },
      /**
      * Reset all the filters when reset button gets pressed
      */
      resetFilter: function() {
        this.activeFilters = {
          start_date: null,
          end_date: null,
        };
        this.filterData = {
          createdAt: null,
          logTypeId: null,
          projectName: '',
          orderId:  null,
          updatedBy:  null, 
        };
        this.getAllData();
      },
      /*
        * get all selectBoxData from Api/LogController@getSelectBox
        * Each select box data will be returned as an array of objects
        * this is used in combobox
      */
      retrieveSelectBoxData: async function() {
        let url = '/logs/select-box'
        this.isLoadingTableData = true;
        try {
          const res = await getSelectBoxData(url);
          console.log("this is res");
          console.log(res);

          Object.entries(this.selectBoxData).forEach(([key, value]) => {
            if (key === 'createdAt') {
              this.selectBoxData[key] = this.sortDates(this.monthYearOnlyHelper(res[key]))
            }else {
              this.selectBoxData[key] = res[key];
            }
          });

          console.log("this is selectBoxData");
          console.log(this.selectBoxData);
        } catch(err) {
          console.log(err);
        } finally {
          this.isLoadingTableData = false
        }
      },
      sortDates: function(date_array) {
        let res = date_array
          .map(item => item.date ?? '')
          .sort(function(a, b) {
            if (a < b) {
              return 1;
            } else if (a > b) {
              return -1;
            } else {
              return 0;
            }
          });
        return res;
      },
      tooltipItem: function(object) {
        if (object.length !== 0) {
          let tooltipText = [];
          if (typeof object[0].name_jp === 'string') {
            object.forEach(element => {
              this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
            });
          } else if (typeof object[0] === 'string') {
            object.forEach(element => {
              tooltipText.push(element);
            });
          } else {
            object.forEach(element => {
              tooltipText.push(element.name);
            });
          }
          return tooltipText;
        } else {
          return this.$t('general.tooltip.noSelect');
        }
      },
      /**
       * Update data table when sorting, searching or pagination are requested
       */
      updateDataTable: function(resetPageNumber = false) {
        this.getAllData();
      },
      updateFilter: function(attr, val, date = false) {
        if (val) {
          if (Array.isArray(val)) {
            if (date == true) {
              val.forEach(function(value, index, thisArray) {
                thisArray[index] = value.replace('-', '');
              });
            }
            let value = [];
            if (attr == 'createdAt') {
              val.forEach(element => {
                value.push(element);
              })
            }else if (attr == 'logTypeId') {
              val.forEach(element => {
                value.push(element.id);
              })
            }else if(attr == 'projectName') {
              val.forEach(element => {
                value.push(element.id);
              })
            }else if(attr == 'orderId') {
              val.forEach(element => {
                value.push(element.id);
              })
            }else if(attr == 'updatedBy') {
              val.forEach(element => {
                value.push(element.id);
              })
            }else{
              val.forEach(element => {
                value.push(element.id);
              })
            }
            val = value.join('||');
          }
          this.$set(this.activeFilters, attr, val);
        }else {
          this.$delete(this.activeFilters, attr);
        }
      },
      updateAllFilters: function() {
        this.updateFilter('createdAt', this.filterData.createdAt);
        this.updateFilter('logTypeId', this.filterData.logTypeId);
        this.updateFilter('projectName', this.filterData.projectName);
        this.updateFilter('orderId', this.filterData.orderId);
        this.updateFilter('updatedBy', this.filterData.updatedBy);
      },
    },
  }
</script>

<style lang="scss">
  .modification_history {
    .page-innerset {
      max-height: calc(100vh - 118px) !important;
      overflow-y: hidden;
    }
    .v-data-table {
      // border: 2px solid red;
      max-height: calc(100% - 90px)  !important;
    }
    .checkbox-style {
      border-radius: 4px;
    }
    .input-title {
      font-size: 0.9rem;
    }
    // setting v-data-table 
    .v-data-table > .v-data-table__wrapper > table {
      & > thead > tr > th,
      & > tbody > tr > th,
      & > tfoot > tr > th {
        font-size: 11px !important;
      }
    }
    .v-data-table > .v-data-table__wrapper > table {
      & > tbody > tr > td,
      & > thead > tr > td,
      & > tfoot > tr > td {
        font-size: 11px !important;
      }
    }
    
    // setting select box
    .v-select__selections {
      font-size: 11px !important;
      overflow-x: auto;
      flex-wrap: nowrap;
    }
    // setting scrollbar
    .v-select__selections::-webkit-scrollbar {
      width: 16px;
      height: 5px;
    }
    .v-select__selections::-webkit-scrollbar-track {
      background-color:white;
    }
    .v-select__selections::-webkit-scrollbar-thumb {
      background-color: darkgray;
    }
  }
</style>

