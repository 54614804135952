<template>
  <div class="daily-report">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.reports.list.pageTitle') }}</span>
    </p>
    <!-- Page Title -->
    <PageInnerSet :title="$t('general.reports.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <v-row cols="12">
          <DateSearch :activeFilters="activeFilters" :rules="rules" :DateType="'date'"></DateSearch>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    :title="$t('general.reports.projectName') + '：'"
                    :title_width="4"
                    :items="selectBoxData.project_names"
                    v-bind="attrs"
                    v-model="activeFilters.project_names"
                  />
                </div>
              </template>
              <span>{{ activeFilters.project_names ? tooltipItem(activeFilters.project_names) : '' }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    v-if="isLocaleJapanese"
                    :title="$t('general.reports.orderName') + '：'"
                    :title_width="2"
                    :items="selectBoxData.order_names_jp"
                    v-bind="attrs"
                    v-model="activeFilters.order_names_jp"
                  />
                  <FilterTextSelect
                    v-else
                    :title="$t('general.reports.orderName') + '：'"
                    :title_width="2"
                    :items="selectBoxData.order_names_en"
                    v-model="activeFilters.order_names_en"
                    v-bind="attrs"
                  />
                </div>
              </template>
              <span>{{
                isLocaleJapanese ? tooltipItem(activeFilters.order_names_jp) : tooltipItem(activeFilters.order_names_en)
              }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto">
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <div class="mb-4 mt-8">
        <v-row class="mx-0 justify-space-between" cols="12">
          <v-col cols="12" md="6" class="px-0 d-flex align-center">
            <div class="font-weight-bold text-h6">
              {{ $t('general.reports.totalWorkingTime') }} : {{ totalHours }}H
            </div>
          </v-col>

          <v-col cols="12" md="auto">
            <div class="sorting-perpage">
              <FilterSelectBox
                ref="filterDisplayResult"
                :items="[50, 100, 200, 500]"
                v-model="options.perPage"
                :title="$t('general.form.displayedResults')"
                @input="onPerPageChange"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- data talbe  -->
      <v-data-table
        :headers="headers"
        :items="reports"
        :options.sync="options"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        :items-per-page="-1"
        hide-default-footer
        fixed-header
        height="700px"
        dense
        @update:options="updatePagination"
      >
        <template v-slot:[`item.date`]="{ item }">
          <div class="title text--black">
            {{
              reports.indexOf(item) != 0
                ? reports[reports.indexOf(item) - 1].report_id == item.report_id
                  ? ''
                  : item.date
                : item.date
            }}
          </div>
          <v-btn
            v-if="reports.indexOf(item) == 0 || reports[reports.indexOf(item) - 1].report_id != item.report_id"
            :to="{ name: 'reports.edit', params: { id: item.report_id } }"
            depressed
            color="primary"
            width="100%"
            x-small
          >
            {{ $t('general.crud.edit') }}
          </v-btn>
        </template>
        <template v-slot:[`item.total_hours`]="{ item }">
          <div class="title text--black">
            {{
              reports.indexOf(item) != 0
                ? reports[reports.indexOf(item) - 1].report_id == item.report_id
                  ? ''
                  : item.total_hours
                : item.total_hours
            }}
          </div>
        </template>
        <template v-slot:[`item.project_name`]="{ item }">
          {{ item.project_name }}
        </template>
        <template v-slot:[`item.order_name`]="{ item }">
          <p>{{ item.order_name_jp }}</p>
          <p>{{ item.order_name_en }}</p>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          {{ item.time }}
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          {{ item.progress }}
        </template>
      </v-data-table>

      <div class="text-center my-8">
        <v-pagination
          v-model="currentPage" 
          :length="totalPages" 
          total-visible="7"
          @input="onPageChange"
        />
      </div>
    </PageInnerSet>
  </div>
</template>

<script>
import { getAll } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
// import FilterSelect from '@views/_components/datatable_filter/TableFilterSelect';
import DateSearch from '@views/_components/organisms/search/DateSearch.vue';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import FilterSelectBox from '@views/_components/datatable_filter/TableFilterSelectBox';

export default {
  metaInfo() {
    return {
      title: this.$t('general.reports.list.metaTitle'),
    };
  },
  components: {
    DateSearch,
    PageInnerSet,
    // FilterSelect,
    // GDatePicker,
    // FilterReset,
    // FilterRange,
    FilterTextSelect,
    FilterSelectBox
  },
  data: function() {
    return {
      rules: {
        start_date: [
          v =>
            new Date(v).getTime() <= new Date().getTime() ||
            this.$t('general.reports.message.start_date.greaterThanToday'),

          v =>
            this.activeFilters.end_date == null ||
            this.activeFilters.start_date == null ||
            new Date(this.activeFilters.start_date).getTime() <= new Date(this.activeFilters.end_date).getTime() ||
            this.$t('general.reports.message.start_date.greaterThanDateEnd'),
        ],
        end_date: [
          v =>
            new Date(v).getTime() <= new Date().getTime() ||
            this.$t('general.reports.message.end_date.greaterThanToday'),

          v =>
            this.activeFilters.start_date == null ||
            this.activeFilters.end_date == null ||
            new Date(this.activeFilters.end_date).getTime() >= new Date(this.activeFilters.start_date).getTime() ||
            this.$t('general.reports.message.end_date.lessThanDateStart'),
        ],
      },
      reports: [],
      activeFilters: {
        start_date: null,
        end_date: null,
        project_names: [],
        order_names_jp: [],
        order_names_en: [],
      },
      selectBoxData: {
        project_names: [],
        order_names_jp: [],
        order_names_en: [],
      },
      options: {
        perPage: parseInt(localStorage.getItem('reports-items-per-page')) || 100, // Default per page
        page: 1,
        sortBy: ['date'],
        sortDesc: [true],
      },
      currentPage: 1,
      totalPages: 0,
      totalHours: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false,
      timer: null
    };
  },
  mounted: function() {
    this.getAllReports();
    this.retrieveSelectBoxData();
  },
  watch: {
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
    activeFilters: {
      handler() {
        if (!this.$refs.filter.validate()) return;
        
        // Check the timer
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.reports.date'),
          value: 'date',
          width: '14%',
          sortable: true,
        },
        {
          text: this.$t('general.reports.total'),
          value: 'total_hours',
          width: '10%',
          sortable: false,
          divider: true,
        },
        { text: this.$t('general.reports.projectName'), value: 'project_name', width: '20%', sortable: false },
        { text: this.$t('general.reports.orderName'), value: 'order_name', width: '20%', sortable: false },
        { text: this.$t('general.reports.time'), value: 'time', width: '6%', sortable: false },
        { text: this.$t('general.reports.progress'), value: 'progress', width: '20%', sortable: false },
      ];
    },
    date1: () => {
      return;
    },
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber=false) {
      this.getAllReports();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    resetFilter: function() {
      this.$refs.filter.reset();
      // Reset the pagination options
      this.options = {
        perPage: 100,
        page: 1,
      }
      this.currentPage = 1;
    },
    getAllReports: async function() {
      console.log('getAllReports', this.$store.state.auth.info.role);
      let url = '/reports';
      this.isLoadingTableData = true;
      try {
        const { perPage, page, sortBy, sortDesc } = this.options;
        const res = await getAll(url, { perPage, page, sortBy, sortDesc, ...this.activeFilters });
        this.reports = res.data;
        this.totalPages = res.last_page;
        this.totalHours = res.total_hours;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    retrieveSelectBoxData: async function() {
      let url = '/reports/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getAll(url);
        this.selectBoxData.project_names = res.project_names;
        this.selectBoxData.order_names_jp = res.order_names_jp;
        this.selectBoxData.order_names_en = res.order_names_en;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    updatePagination(newOptions) {
      this.options = newOptions;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
      this.options.page = newPage;
    },
    onPerPageChange(newSize) {
      this.options.perPage = newSize;
      localStorage.setItem('reports-items-per-page', newSize);
      this.currentPage = 1; // Reset to the first page
      this.options.page = 1; // Ensure options reflect the reset
    },
  },
};
</script>

<style lang="scss">
.daily-report {
  .sorting-perpage {
    display: flex;
    justify-content: flex-end;
    width: 100%;    

    .center_element{
      max-width: 200px;
      margin-top: 0!important;

      .col-md-2{
        width: 100px;
        padding-bottom: 0;

        @media screen and (min-width: 768px) {
          min-width: 100px;
        }
      }
      .col-md-10{
        max-width: 100px;

        .v-text-field__details{
          display: none;
        }
      }
    }
  }
}
  /* .v-data-table {
    // border: 2px solid red;
    height: calc(100vh - 330px)  !important;
  }
  .v-data-table__wrapper {
    border: 0 !important;
  }
  .v-data-table th {
    border: 0;
  }
  .v-data-table td {
    border: 0;
  }
  .v-pagination__navigation {
    display: none;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color: white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  .v-chip {
    overflow: initial;
    font-size: 11px !important;
  }

  .v-select__selection--comma {
    overflow: hidden;
  }
}

.v-input__control {
  .v-input__slot {
    padding-bottom: 5px !important;
  }
} */
</style>
