<template>
  <div class="project">
    <!-- Breadcrumbs -->
    <p class="mx-1 mb-0 text-caption text--secondary">
      <router-link :to="{ name: 'dashboard' }">{{ $t('general.nav.dashboard') }}</router-link>
      <span class="mx-2">></span>
      <span>{{ $t('general.project.list.pageTitle') }}</span>
    </p>
    <!-- Page container -->
    <PageInnerSet :title="$t('general.project.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <!-- <span class="text-h6 text--secondary">> {{ $t('general.reports.condition') }}</span> <br /> -->
        <!-- <FilterReset @click="resetFilter" class="pb-10"></FilterReset> -->
        <v-row cols="12">
          <v-col cols="3">
            <div>
              <FilterSelect
                v-if="isLocaleJapanese()"
                :title="$t('general.project.type') + '：'"
                :title_width="3"
                :items="selectBoxData.projectTypes"
                :option="['id', 'name_jp']"
                chips
                deletable-chips
                v-model="searchingFormData.projectTypeId"
                @input="updateAllFilters"
              />
              <FilterSelect
                v-else
                :title="$t('general.project.type') + '：'"
                :title_width="3"
                :items="selectBoxData.projectTypes"
                :option="['id', 'name_en']"
                v-model="searchingFormData.projectTypeId"
                @input="updateAllFilters"
              />
            </div>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    :title="$t('general.project.name') + '：'"
                    :title_width="4"
                    :items="selectBoxData.projectNames"
                    v-bind="attrs"
                    v-model="searchingFormData.projectName"
                    @input="updateAllFilters"
                  />
                </div>
              </template>
              <span>{{ searchingFormData.projectName ? tooltipItem(searchingFormData.projectName) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div multiple v-on="on">
                  <FilterTextSelect
                    :title="$t('general.project.customer') + '：'"
                    :title_width="3"
                    :items="selectBoxData.customerNames"
                    v-bind="attrs"
                    v-model="searchingFormData.customerName"
                    @input="updateAllFilters"
                  />
                </div>
              </template>
              <span>{{ searchingFormData.customerName ? tooltipItem(searchingFormData.customerName) : $t('general.tooltip.noSelect') }}</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <!-- <v-row class="pt-5">
        <v-col cols="12">
          <span class="text-h6 text--secondary">> {{ $t('general.reports.result') }}</span>
        </v-col>
      </v-row> -->
      <br />
      <div class="sorting-perpage">
        <FilterSelectBox
          :items="[50, 100, 200, 500]"
          v-model="options.itemsPerPage"
          title="Rows per page :"
          @input="handlePageSizeChange"
        />
      </div>

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="projects"
        :options.sync="options"
        :page.sync="currentPage"
        :items-per-page="options.itemsPerPage"
        :server-items-length="totalProjects"
        :loading="isLoadingTableData"
        :loading-text="$t('general.crud.loading')"
        hide-default-footer
        @page-count="totalPages = $event"
        fixed-header
        dense
        @update:options="handlePaginationChange"
      >
        <template v-if="isLocaleJapanese()" v-slot:item.project_type_id="{ item }">
          {{ item.project_type.name_jp }}
        </template>
        <template v-else v-slot:item.project_type_id="{ item }">
          {{ item.project_type.name_en }}
        </template>

        <template v-if="isHQ()" v-slot:item.action="{ item }">
          <v-btn
            :disabled="isLoadingTableData"
            :to="{ name: 'projects.edit', params: { id: item.id } }"
            color="primary"
            class="mx-2 white--text"
            x-small
            >{{ $t('general.crud.edit') }}</v-btn
          >
        </template>
      </v-data-table>

      <!-- <div class="text-center pt-2 mt-3">
        <v-pagination v-model="currentPage" :length="totalPages" total-visible="7"></v-pagination>
      </div> -->

      <!-- Pagination Controls -->
      <div class="pt-5">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="7"
          @input="handlePageChange"
        />
      </div>

    </PageInnerSet>
  </div>
</template>

<script>
/**
 * 29 June 2021 documented by Satoshi Sugai
 *
 * How searching form works
 * 1. As soon as one of the searching form items gets input, run "updateAllFilters()" method runs
 * 2. Inside "updateAllFilters()", "updateFilter()" method gets called. It sets/clears filter("activeFilters")) to be sent to api
 * 3. Once "activeFilters" gets changed, retrieve list data from api again. This happens also when reset text gets pressed.
 *
 * How pagination works
 * - While you are in say page 2 and use a filter, the page will be automatically set to 1.
 *
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 *
 */

/* July 1st 2021 documented by Tomas Sullivan
 * TODO:
 * - Set path to edit page on the setting button in the data table
 * - Separate searching form amd data table
 * - Add meta file.
 * - Add subtitles for searching form and data table
 *
 */

import { getAll, getSelectBoxData } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';

import FilterReset from '@views/_components/datatable_filter/TableFilterReset';
import FilterSelect from '@views/_components/datatable_filter/TableFilterSelect';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import FilterSelectBox from '@views/_components/datatable_filter/TableFilterSelectBox';
//import Pagination from '@views/_components/Page/Pagination';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.project.list'),
    };
  },
  components: {
    // FilterReset,
    FilterSelect,
    FilterTextSelect,
    PageInnerSet,
    FilterSelectBox,
  },
  data: function() {
    return {
      projects: [],
      totalProjects: 0,
      options: {
        itemsPerPage: parseInt(localStorage.getItem('projects-items-per-page')) || 100,
        page: 1,
        multiSort: false,
        sortBy: [],
        sortDesc: [],
      },
      searchingFormData: {
        projectTypeId: 0,
        projectName: '',
        customerName: '',
      },
      activeFilters: {},
      selectBoxData: {
        projectTypes: [],
        projectNames: [],
        customerNames: [],
      },
      currentPage: 1,
      totalPages: 0,
      isLoadingTableData: false, // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
    };
  },
  mounted: function() {
    this.getAllProjects();
    this.retrieveSelectBoxData();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler() {
        this.updateDataTable();
      },
      deep: true,
    },
  },
  computed: {
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        {
          text: this.$t('general.project.type'),
          value: 'project_type_id',
          sortable: true,
        },
        {
          text: this.$t('general.project.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('general.project.customer'),
          value: 'customer.name',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          align: 'center fixed',
          class: 'fixed',
        },
      ];
    },
  },
  methods: {
    tooltipItem: function(object) {
      if (object.length !== 0) {
        let tooltipText = [];
        if (typeof object[0].name_jp === 'string') {
          object.forEach(element => {
            this.isLocaleJapanese ? tooltipText.push(element.name_jp) : tooltipText.push(element.name_en);
          });
        } else if (typeof object[0] === 'string') {
          object.forEach(element => {
            tooltipText.push(element);
          });
        } else {
          object.forEach(element => {
            tooltipText.push(element.name);
          });
        }
        return tooltipText;
      } else {
        return this.$t('general.tooltip.noSelect');
      }
    },
    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    isLocaleJapanese: function() {
      return this.$store.state.global.locale === 'ja';
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data
     */
    getAllProjects: async function() {
      let url = 'projects';
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.projects = res.projects;
        this.totalProjects = res.total;
        this.totalPages = Math.ceil(res.total / itemsPerPage);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber = false) {
      this.getAllProjects();
      if (resetPageNumber) {
        this.currentPage = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      this.$refs.filter.reset();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('projectTypeId', this.searchingFormData.projectTypeId);
      this.updateFilter('projectName', this.searchingFormData.projectName);
      this.updateFilter('customerName', this.searchingFormData.customerName);
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page
     * Not all of our filters are arrays so
     */
    updateFilter: function(attr, val) {
      if (val) {
        if (Array.isArray(val)) {
          val = val.join('||');
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/projects/select-box';
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url);
        this.selectBoxData.projectTypes = res.projectTypes;
        this.selectBoxData.projectNames = res.projectNames.map(item => item.name);
        this.selectBoxData.customerNames = res.customerNames.map(item => item.name);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },

    handlePaginationChange(newOptions) {
      this.options = newOptions;
      this.getAllProjects();
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.options.page = newPage;
      this.getAllProjects();
    },
    handlePageSizeChange(newSize) {
      this.options.itemsPerPage = newSize;
      localStorage.setItem('projects-items-per-page', newSize);
      this.currentPage = 1; // Reset to the first page
      this.options.page = 1; // Ensure options reflect the reset
      this.getAllProjects();
    },
  },
};
</script>

<style lang="scss">
.project {
  .sorting-perpage{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 15px;
    padding-bottom: 16px;

    .center_element{
      max-width: 200px;

      .col-md-2{
        width: 100px;
        padding-bottom: 0;

        @media screen and (min-width: 768px) {
          min-width: 100px;
        }
      }
      .col-md-10{
        max-width: 100px;

        .v-text-field__details{
          display: none;
        }
      }
    }
  }
  .v-data-table {
    // border: 2px solid red;
    height: 100% !important;
  }
  .v-data-table__wrapper {
    border: 0 !important;
  }
  .center-element {
    display: flex;
    align-items: center;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 11px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 11px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
}
.v-input__control {
  .v-input__slot {
    padding-bottom: 5px !important;
  }
}
</style>
