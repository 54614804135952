<template>
  <table class="table table-bordered table-tree">
    <thead>
      <tr>
        <!-- Empty header when tabel has label - Start -->
        <th scope="col" class="column-label" rowspan="3" :style="styles">
          {{ $t('general.nav.members') }}
        </th>
        <!-- Empty header when tabel has label - End -->

        <!-- Months - Start -->
        <th v-for="month in monthHeaders" :key="month.label" :colspan="month.colspan">
          <span>{{ month.label }}</span>
        </th>
        <!-- Months - End -->
      </tr>
      <tr>
        <!-- Dates within the range - Start -->
        <th scope="col" class="column-label-date" v-for="header in headers" :key="header" style="width:47px">
          <span>{{ header | moment('D') }}</span>
        </th>
        <!-- Dates within the range - End -->
      </tr>
      <tr>
        <!-- Days within the range - Start -->
        <th scope="col" class="column-label-date" v-for="header in headers" :key="header" style="width:47px">
          <span>{{ header | moment('ddd') }}</span>
        </th>
        <!-- Days within the range - End -->
      </tr>
    </thead>

    <tbody>
      <template v-if="members.length">
        <Member
          v-for="member in members"
          :key="member.data.id"
          :member="member.data"
          :projects="member.projects"
          :morningProjects="member.morning_projects"
          :projectsOfSchedule="member.schedule"
          :projectsOfAssignment="member.assignment"
          :headers="headers"
          :reports="reports"
          :morningReports="morningReports"
          :schedules="schedules"
          :assignments="assignments"
          :drawerOpen="drawerOpen"
          :display="display"
          :assignmentDialog="assignmentDialog"
          @assignment-form="handleAssignmentForm"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import { find, each } from 'lodash';
import Member from './Member';

export default {
  components: {
    Member,
  },

  props: {
    headers: { type: Array, default: () => [] },
    members: { type: Array, default: () => [] },
    reports: { type: Array, default: () => [] },
    morningReports: { type: Array, default: () => [] },
    schedules: { type: Array, default: () => [] },
    assignments: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    drawerOpen: { type: Boolean, default: true },
    display: { type: Object },
    assignmentDialog: { type: Function },
  },

  computed: {
    // Calculate month headers
    monthHeaders() {
      console.log('Reportable of schedule', this.$props);
      const vm = this;

      let container = [];
      let lastMonth = null;

      each(this.headers, function(date) {
        // Current month name
        const monthName = vm.$moment(date).format('MMMM');

        // Push a new month to the container on each new month
        if (lastMonth !== monthName) {
          container.push({ label: monthName, colspan: 0 });
        }

        // Find the month item
        const currentMonth = find(container, function(month) {
          return month.label === monthName;
        });

        // If found increment the colspan
        if (currentMonth) currentMonth.colspan++;

        // Store current month for the next iteration
        lastMonth = monthName;
      });

      // Return the month container
      return container;
    },
    styles() {
      return {
        width: '210px',
        left: this.drawerOpen == true ? '0' : '0',
      };
    },
  },

  methods: {
    handleAssignmentForm(assignmentForm) {
      // Pass the assignmentForm values up to the Datatable component
      this.$emit('assignment-form', assignmentForm);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/mixins';

.nav-arrow {
  margin: 0 0 0.75rem !important;
}

@media (max-width: 378px) {
  .nav-arrow {
    .date-label {
      font-size: .75rem;
    }
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: separate;
  height: fit-content;
  border-spacing: 0;
  overflow-x: auto;

  &.table-bordered {
    border: 1px solid #dee2e6;

    th,
    td {
      border: 1px solid #dee2e6;
    }

    thead th,
    thead td {
      border-bottom-width: 2px;
    }
  }

  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;

    .cell-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .full-height {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #ffffff;
        padding: 0.5rem;
      }
    }
  }
}

.table-container {
  overflow-x: auto;

  &.table-loading {
    position: relative;
    min-height: 10rem;
    border: 1px solid #ccc;
  }
}

.table-tree {
  // min-width: 56rem;
  width: 100%;
  font-size: 0.875rem;

  .column-label-date {
    min-width: 47px;
  }

  .column-label {
    min-width: 210px;
    background-color: #f9f9f9;
    position: sticky;
    left: 0;

    .fw-normal {
      font-weight: 500;
      color: #ffffff;
    }

    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      width: 0.125rem;
      background-color: #ffff;
      border-left: 1px solid #ccc;
    }

    &:after {
      content: '';
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      width: 0.125rem;
      background-color: #ffff;
      border-right: 1px solid #ccc;
    }
  }

  .btn-label {
    cursor: pointer;
    position: relative;
    padding-left: 1.5rem;

    & > .arrow {
      font-size: 0.875rem;
      position: absolute;
      left: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  thead {
    background-color: #f9f9f9;
    position: sticky;
    /* top: 64px; */
    z-index: 4;
  }

  tbody {
    $depth: 5;
    $indent-size: 1rem;
    $level-color: lighten(#ecf6fe, 5%);

    tr {
      @for $level from 1 through $depth {
        &.level-#{$level} {
          .column-label {
            padding-left: $indent-size * $level;
          }

          & ~ .row-container > .table-row {
            background-color: darken($level-color, (2.5% * $level));
          }
        }
      }
    }
  }
}

// This content is rendered invisible
.row-container {
  display: contents;

  .table-row {
    $background-hover: #f8f8f8;
    &:hover,
    &:focus {
      background-color: $background-hover !important;
    }
  }

  .btn-label {
    & > .arrow {
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      // @include transition( transform .5s $easeOutExpo );
    }
    &.expanded > .arrow {
      transform: rotate(90deg);
      // @include rotate( 180deg );
    }
  }
}
</style>
